import React, { useState } from "react"
import styled from "styled-components"
import { standardWrapper } from "../../Utilities"
import GallerySlider from "./GallerySlider"

const ImageGallery = ({ gallery, featuredImage, alt_text }) => {
  const [isSliderActive, setIsSliderActive] = useState(false)
  const [clikcedImage, setClikcedImage] = useState(0)
  return (
    <GalleryContainer>
      <div className="wrapper">
        {gallery.map((image, index) => {
          return (
            <GalleryImage
              className="galleryImage"
              key={index}
              onClick={() => {
                setIsSliderActive(true)
                setClikcedImage(index)
              }}
              bgimage={image.url}
            >
              <div className="proBgImg">
                <div className="proBgImg__bgImage" />
              </div>

              <div className="imageGalList__icon">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="200"
                    height="200"
                    data-name="Layer 1"
                    viewBox="0 0 200 200"
                  >
                    <g fill="#111">
                      <path d="M143.92 92.42h-36.55V55.87a7.38 7.38 0 00-14.75 0v36.55H56.07a7.38 7.38 0 000 14.75h36.55v36.55a7.38 7.38 0 0014.75 0v-36.55h36.55a7.38 7.38 0 000-14.75z"></path>
                      <path d="M186.4 63.29a94.09 94.09 0 107.37 36.5 93.53 93.53 0 00-7.37-36.5zM100 183.78a84 84 0 1184-84 84.08 84.08 0 01-84 84z"></path>
                    </g>
                  </svg>
                </div>
              </div>
            </GalleryImage>
          )
        })}
      </div>
      {isSliderActive && (
        <GallerySlider
          images={gallery}
          setIsSliderActive={setIsSliderActive}
          clikcedImage={clikcedImage}
        />
      )}
    </GalleryContainer>
  )
}

const GalleryContainer = styled.div`
  .wrapper {
    ${standardWrapper};
    justify-content: flex-start !important;
    margin: 0;
    padding: 0 !important;
  }
  @media (min-width: 768px) {
  }

  .galleryImage:nth-of-type(3n + 1) {
    width: calc(33.33% - 1rem) !important;
    margin: 1rem;
    margin-left: 0;

    @media (min-width: 1025px) {
      width: calc(33.33% - 1rem) !important;
      margin: 1rem;
      margin-left: 0;
    }
  }

  .galleryImage:nth-of-type(3n + 3) {
    width: calc(33.33% - 1rem) !important;
    margin: 1rem;
    margin-right: 0;

    @media (min-width: 1025px) {
      width: calc(33.33% - 1rem) !important;
      margin: 1rem;
      margin-right: 0;
    }
  }
`

const GalleryImage = styled.div`
  position: relative;
  overflow: hidden;
  width: calc(33.33% - 2rem);
  margin: 1rem;

  @media (min-width: 768px) {
    width: calc(33.33% - 2rem);
  }
  @media (min-width: 1025px) {
    width: calc(33.33% - 2rem);
    margin: 1rem;
  }

  .proBgImg {
    position: relative;
    width: 100%;
    height: 33.33vw;
    max-height: 14.5rem;

    &__bgImage {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: ${props => `url(${props.bgimage})`};
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  .imageGalList__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 5rem;
    height: 5rem;
    transition: all 0.4s ease-out;
    transform: translate(1200%, -50%);
    z-index: 100;

    svg {
      width: 5rem;
      height: 5rem;
      g {
        fill: #fff;
      }
    }
  }

  &::after {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.25s ease-out;
    background-color: rgba(218, 53, 70, 0.75);
    content: "";
    opacity: 0;
  }

  &:hover {
    cursor: pointer;
    .imageGalList__icon {
      transform: translate(-50%, -50%);
    }
    &::after {
      opacity: 1;
    }
  }
`

export default ImageGallery
