import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Details from "../components/Product/Details"
import Suggestions from "../components/Product/Suggestions"

const Product = (props) => {
  const { product, products } = props.data

  const filteredProducts = products.edges.filter(
    (pro) => pro.node.id !== product.id
  )

  let suggestions = []

  const productsRemovedAdventures = filteredProducts.filter(
    (pro) => pro.node.categories[0].slug !== "adventures"
  )

  const filteredProductsRemovedAdventures = filteredProducts.filter(
    (pro) => pro.node.categories[0].slug !== "adventures"
  )

  if (productsRemovedAdventures.length > 4) {
    const shuffled = filteredProductsRemovedAdventures.sort(
      () => 0.5 - Math.random()
    )
    suggestions = shuffled.slice(0, 4)
  } else {
    suggestions = productsRemovedAdventures
  }

  return (
    <Layout location={props.location.pathname}>
      <SEO
        title={``}
        description={``}
        metaImg={``}
        location={props.location.pathname}
      />
      <Details details={product} />
      <Suggestions suggestions={suggestions} />
    </Layout>
  )
}

export const query = graphql`
  query productPage($id: String!) {
    product: strapiProduct(id: { eq: $id }) {
      name
      id
      strapiId
      slug
      price
      stock
      description
      should_charge_tax
      should_charge_shipping
      product_variants {
        options_name
        id
        option_values {
          options_name
          id
        }
      }
      variations {
        name
        id
        stock
        variation_array
        variation_name
        image {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      featured_image {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image_gallery {
        name
        hash
        url
        formats {
          large {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }

      categories {
        name
        slug
      }
    }

    products: allStrapiProduct {
      edges {
        node {
          name
          id
          strapiId
          slug
          price
          featured_image {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          categories {
            name
            slug
          }
        }
      }
    }
  }
`

export default Product
