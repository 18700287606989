import React from "react"
import styled from "styled-components"
import { colors, headlineThree, standardWrapper } from "../../Utilities"
import CatProduct from "../Category/CatProduct"

const Suggestions = ({ suggestions }) => {
  return (
    <SuggestionsStyled>
      <div className="headline">
        <h3>You Might Also Like</h3>
      </div>
      <div className="wrapper">
        <div className="products">
          {suggestions.map(product => {
            return <CatProduct key={product.node.id} product={product.node} />
          })}
        </div>
      </div>
    </SuggestionsStyled>
  )
}

const SuggestionsStyled = styled.div`
  .wrapper {
    ${standardWrapper};
  }

  .headline {
    width: 100%;
    padding: 1rem 4rem;
    background-color: ${colors.colorPrimary};
    text-align: center;

    h3 {
      ${headlineThree};
      margin: 0;
      color: ${colors.white};
      text-transform: uppercase;
      line-height: 1.25;
    }
  }

  .products {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
  }
`

export default Suggestions
