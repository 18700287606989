import React from "react"
import styled from "styled-components"
import BGImage from "gatsby-background-image"
import { Link } from "gatsby"
import { bodyCopy, buttonOne, colors, headlineThree } from "../../Utilities"
import { priceInCents } from "../../Utilities/cart/cart"
import { formatPrice } from "../../Utilities/cart/format"

const CatProduct = ({ product, name }) => {
  return (
    <CatProductStyled>
      <div className="productImage">
        <Link to={`/products/${product.slug}`}>
          <BGImage
            tag="div"
            fluid={product.featured_image.childImageSharp.fluid}
          />
        </Link>
      </div>
      <div className="productDetails">
        <div className="productDetails__meta">
          <p>{name}</p>
          <h2>{product.name}</h2>
        </div>
        <div className="productDetails__price">
          <p>{formatPrice(priceInCents(product.price))}</p>
        </div>
        <div className="productDetails__link">
          <Link to={`/products/${product.slug}`}>View</Link>
        </div>
      </div>
    </CatProductStyled>
  )
}

const CatProductStyled = styled.div`
  position: relative;
  width: calc(1000% - 4rem);
  margin: 2rem;
  padding-bottom: 8rem;

  @media (min-width: 500px) {
    width: calc(50% - 4rem);
    margin: 2rem;
    padding-bottom: 8rem;
  }

  @media (min-width: 768px) {
    width: calc(50% - 4rem);
    margin: 2rem;
    padding-bottom: 6.5rem;
  }

  @media (min-width: 1025px) {
    width: calc(25% - 4rem);
    margin: 2rem;
    padding-bottom: 7.5rem;
  }

  .productImage {
    width: 100%;
    height: 100vw;
    max-height: 30rem;

    @media (min-width: 768px) {
      height: 50vw;
    }

    @media (min-width: 1025px) {
      height: 25vw;
      max-height: 22rem;
    }

    div {
      width: 100%;
      height: 100%;
    }
  }

  .productDetails {
    margin-top: 1rem;

    &__meta {
      p {
        ${bodyCopy};
        margin: 0;
        text-transform: uppercase;
      }

      h2 {
        ${headlineThree};
        margin: 0;
        color: ${colors.colorSecondary};
        line-height: 1.5;
      }
    }

    &__price {
      p {
        ${headlineThree};
        margin: 0;
        color: ${colors.colorSecondary};
        line-height: 1.5;
      }
    }

    &__link {
      position: absolute;
      right: 0;
      bottom: 1rem;
      left: 0;
      margin-top: 3rem;
      margin-right: auto;
      margin-left: auto;
      text-align: center;

      a,
      button {
        ${buttonOne};
        text-align: center;
      }
    }
  }
`

export default CatProduct
