import React, { useState, useContext, useEffect } from "react"
import _ from "lodash"
import axios from "axios"
import BGImage from "gatsby-background-image"
import styled from "styled-components"
import { Link } from "gatsby"
import { CartContext } from "../../context/CartContext"
import { UiContext } from "../../context/UiContext"
import {
  bodyCopy,
  buttonOne,
  colors,
  headlineThree,
  standardWrapper,
} from "../../Utilities"
import { priceInCents } from "../../Utilities/cart/cart"
import { formatPrice } from "../../Utilities/cart/format"
import ImageGallery from "./ImageGallery"

const Details = ({ details }) => {
  const [productDetails, setProductDetails] = useState({
    strapi_id: "",
    qty: 1,
    variants: [],
  })
  const [selectedVarient, setSelectedVarient] = useState({})
  const [variantSatisfied, setVariantSatisfied] = useState(false)

  const { cart, cartTotal, addToCart } = useContext(CartContext)
  const uiContext = useContext(UiContext)
  const { state: uiState, dispatch: uiDispatch } = uiContext

  const addQuantityHandler = () => {
    setProductDetails({
      ...productDetails,
      qty: productDetails.qty + 1,
    })
  }

  const subtractQuantityHandler = () => {
    setProductDetails({
      ...productDetails,
      qty: productDetails.qty - 1,
    })
  }

  const handleOnChangeQty = (event) => {
    setProductDetails({
      ...productDetails,
      qty: event.target.value,
    })
  }

  const handleOnChange = (event) => {
    const eventTargetName = event.target.name
    const eventTargetValue = event.target.value
    let stateArrayCopy = productDetails.variants
    let variantsArray = []
    // Find the index of this variant. //
    const updateVariantIndex = stateArrayCopy.findIndex((variant) => {
      return Object.keys(variant)[0] === eventTargetName
    })

    // New Variant Selections, need to add to array. //
    if (updateVariantIndex === -1) {
      variantsArray = [
        ...stateArrayCopy,
        { [eventTargetName]: eventTargetValue },
      ]
    } else {
      stateArrayCopy.splice(updateVariantIndex, 1)
      if (eventTargetValue !== "") {
        const updatedItem = { [eventTargetName]: eventTargetValue }
        stateArrayCopy.push(updatedItem)
      }
      variantsArray = [...stateArrayCopy]
    }

    setProductDetails({
      ...productDetails,
      qty: 1,
      variants: [...variantsArray],
    })
  }

  useEffect(() => {
    // Get this pages product id and add it to the deatails. //
    setProductDetails({
      ...productDetails,
      strapi_id: details.strapiId,
    })
  }, [])

  useEffect(() => {
    if (productDetails.variants.length === details.product_variants.length) {
      setVariantSatisfied(true)
      checkIsInStock()
      return
    } else {
      setVariantSatisfied(false)
      return
    }
  }, [productDetails.variants, cartTotal])

  useEffect(() => {
    if (details.product_variants.length === 0) {
      setVariantSatisfied(true)
      checkIsInStock()
      return
    }
  }, [productDetails.qty])

  const handleAddToCart = (product) => {
    if (!variantSatisfied) return
    if (productDetails.qty <= 0) return
    const newCartProduct = {
      ...product,
      var_selected_stock_id: selectedVarient.id ? selectedVarient.id : null,
      selected_varient_details: selectedVarient.id ? selectedVarient : null,
    }

    addToCart(newCartProduct, productDetails.qty, productDetails.variants)
    uiDispatch({
      type: "ALERT_SUCCESS",
      payload: `${product.name} has been added to your cart`,
    })
    setProductDetails({
      ...productDetails,
      qty: 1,
      variants: [],
    })
  }
  // Check for stock.
  const checkIsInStock = async () => {
    // If the variations have not been selected yet, just exit out.
    // if (!variantSatisfied) return
    // There is no variations of this product, get the total of the product. No need for the variation total. //
    if (productDetails.variants.length === 0) {
      const itemInCart = cart.find((item) => {
        return item.strapiId === productDetails.strapi_id
      })
      uiDispatch({ type: "SERVER_LOAD" })
      const stockOnServer = await axios.get(
        `${process.env.GATSBY_API_URL}/products/${details.strapiId}`
      )

      const qtyAlreadyInCart = itemInCart ? itemInCart.qty : 0
      setSelectedVarient({
        ...details,
        confirmed_stock: stockOnServer.data.stock,
        overall_left_in_stock: stockOnServer.data.stock - qtyAlreadyInCart,
      })
      uiDispatch({ type: "SERVER_LOAD_FINISHED" })
      return
    }
    // There is a variation, we need to get the stock total for this variation of the product. //
    uiDispatch({ type: "SERVER_LOAD" })
    const selectedVarianst = productDetails.variants.map((s) => {
      return Object.values(s)[0]
    })

    console.log("foundMatch: ", foundMatch)

    const foundMatch = details.variations.find((varArr) => {
      console.log("varArr", varArr)
      var lowercasedVarients = varArr.variation_array.map(function (v) {
        console.log(v)
        return v.toLowerCase()
      })

      console.log("lowercasedVarients: ", lowercasedVarients)

      let matchFound = _.isEqual(
        lowercasedVarients.sort(),
        selectedVarianst.sort()
      )

      console.log("matchFound: ", matchFound)

      return matchFound
    })

    const stockOnServer = await axios.get(
      `${process.env.GATSBY_API_URL}/variations/${foundMatch.id}`
    )

    const itemInCart = cart.find((item) => {
      return item.var_selected_stock_id === foundMatch.id
    })

    const qtyAlreadyInCart = itemInCart ? itemInCart.qty : 0

    setSelectedVarient({
      ...foundMatch,
      confirmed_stock: stockOnServer.data.stock,
      overall_left_in_stock: stockOnServer.data.stock - qtyAlreadyInCart,
    })
    uiDispatch({ type: "SERVER_LOAD_FINISHED" })

    return
  }
  const disableAddToCart =
    !variantSatisfied ||
    productDetails.qty <= 0 ||
    selectedVarient === undefined ||
    selectedVarient.overall_left_in_stock <= 0

  const qtyAlreadyInCart =
    selectedVarient.confirmed_stock - selectedVarient.overall_left_in_stock
      ? selectedVarient.confirmed_stock - selectedVarient.overall_left_in_stock
      : 0
  return (
    <DetailsStyled>
      <div className="wrapper">
        <div className="image">
          <div className="image__featured">
            {selectedVarient !== undefined &&
            selectedVarient.image &&
            selectedVarient.image.childImageSharp.fluid ? (
              <>
                <BGImage
                  tag="div"
                  fluid={selectedVarient.image.childImageSharp.fluid}
                />
              </>
            ) : (
              <div>
                <BGImage
                  tag="div"
                  fluid={details.featured_image.childImageSharp.fluid}
                />
              </div>
            )}
          </div>
          {details.image_gallery.length > 0 && (
            <div className="image__gallery">
              <ImageGallery
                gallery={details?.image_gallery}
                featuredImage={details.featured_image}
                alt_text={details.name}
              />
            </div>
          )}
        </div>
        <div className="content">
          <div className="content__cat">
            <p>
              {details.categories.map((cat, index) => {
                return (
                  <Link key={index} to={`/shop/${cat.slug}`}>
                    <span>{cat.name}</span>
                  </Link>
                )
              })}
            </p>
          </div>
          <div className="content__title">
            <h2>{details.name}</h2>
          </div>
          <div className="content__price">
            <p>{formatPrice(priceInCents(details.price))}</p>
          </div>
          <div className="content__description">
            <p>{details.description}</p>
          </div>
          {variantSatisfied &&
            selectedVarient &&
            selectedVarient.confirmed_stock !== undefined && (
              <div className="content__stock">
                {selectedVarient?.confirmed_stock <= 0 ? (
                  <p className="outofstock">
                    <span>
                      Currently {selectedVarient.name} is out of stock. Please
                      try again soon.
                    </span>
                  </p>
                ) : (
                  <p className="qtyInStock">
                    There are currently{" "}
                    <span>
                      {selectedVarient.confirmed_stock} - {selectedVarient.name}{" "}
                    </span>
                    in stock
                  </p>
                )}
                {qtyAlreadyInCart > 0 && (
                  <p className="alreadyInCart">
                    You have{" "}
                    <span>
                      {qtyAlreadyInCart} - {selectedVarient.name}
                    </span>{" "}
                    in your <Link to="/shop/cart">cart</Link> already.
                  </p>
                )}
              </div>
            )}

          {details.product_variants.length > 0 && (
            <div className="content__variants">
              {details.product_variants.map((variant, index) => {
                const dropDownValue = productDetails.variants.find(
                  (exsiVar) => {
                    if (
                      Object.keys(exsiVar)[0] ===
                      variant.options_name.toLowerCase()
                    ) {
                      return true
                    }
                  }
                )

                const dropDownValueDisplay = dropDownValue
                  ? dropDownValue[variant.options_name.toLowerCase()]
                  : false

                return (
                  <div className="variant-dropdown" key={variant.id}>
                    <label htmlFor={variant.options_name.toLowerCase()}>
                      {variant.options_name}
                    </label>

                    <select
                      id={variant.options_name.toLowerCase()}
                      name={variant.options_name.toLowerCase()}
                      onChange={handleOnChange}
                      value={dropDownValueDisplay ? dropDownValueDisplay : ""}
                    >
                      <option value="">
                        {" "}
                        -- select a {variant.options_name} --{" "}
                      </option>
                      {variant.option_values.map((value) => {
                        return (
                          <option
                            key={value.id}
                            value={value.options_name.toLowerCase()}
                          >
                            {value.options_name}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                )
              })}
            </div>
          )}
          <div className="content__quantity">
            <label>QTY</label>
            <input
              type="number"
              name="quantity"
              min="1"
              max={
                selectedVarient?.overall_left_in_stock
                  ? selectedVarient?.overall_left_in_stock
                  : 1
              }
              step="1"
              value={productDetails.qty}
              readOnly
              onChange={(event) => handleOnChangeQty(event)}
            />
            <div className="quantity-nav">
              <button
                disabled={
                  selectedVarient?.overall_left_in_stock <= productDetails.qty
                }
                onClick={addQuantityHandler}
                className="quantity-nav__add"
                type="button"
              >
                &#43;
              </button>
              <button
                disabled={productDetails.qty <= 1}
                onClick={subtractQuantityHandler}
                className="quantity-nav__subtract"
                type="button"
              >
                &#8722;
              </button>
            </div>
          </div>
          <div className="content__addCard">
            <button
              onClick={() => handleAddToCart(details)}
              disabled={disableAddToCart}
              type="button"
              aria-label="Add to cart"
            >
              Add To Cart
            </button>
          </div>
        </div>
      </div>
    </DetailsStyled>
  )
}

const DetailsStyled = styled.div`
  margin-bottom: 3rem;
  @media (min-width: 768px) {
    margin-bottom: 2rem;
  }

  .wrapper {
    ${standardWrapper};
  }

  .image {
    width: calc(100%);
    margin-bottom: 3rem;

    @media (min-width: 768px) {
      width: calc(50% - 2rem);
      margin-right: 2rem;
      margin-bottom: 0;
    }

    &__featured {
      width: 100%;
      height: 50vw;
      max-height: 48.5rem;

      div {
        width: 100%;
        height: 100%;
      }
    }

    &__gallery {
      width: 100%;
    }
  }

  .content {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(50% - 2rem);
      margin-left: 2rem;
    }

    &__cat {
      p,
      a {
        ${bodyCopy};
        margin: 0;
        text-transform: uppercase;
      }

      a:hover {
        color: ${colors.colorTertiary};
      }
    }

    &__title {
      h2 {
        ${headlineThree};
        margin: 0;
        color: ${colors.colorSecondary};
        text-transform: uppercase;
        line-height: 1.5;
      }
    }

    &__price {
      p {
        ${headlineThree};
        margin: 0;
        color: ${colors.colorSecondary};
        line-height: 1.5;
      }
    }

    &__description {
      margin-top: 2.5rem;

      p {
        ${bodyCopy};
      }
    }

    &__stock {
      p {
        ${bodyCopy};
        margin-bottom: 0.25rem;

        span {
          font-weight: bold;
        }

        &.qtyInStock {
          span {
            color: ${colors.colorPrimary};
          }
        }

        &.outofstock {
          span {
            color: ${colors.colorTertiary};
          }
        }

        &.alreadyInCart {
          span {
            color: ${colors.colorSecondary};
          }
        }
      }
    }

    &__variants {
      margin: 2rem auto;
      .variant-dropdown {
        width: 100%;
        margin: 3rem 0;

        label {
          display: block;
          width: 100%;
          margin-bottom: 1rem;
          color: ${colors.colorShad};
        }

        select {
          background: transparent;
          border: 0.1rem solid ${colors.black};
          font-size: 1.4rem;
          height: 3rem;
          padding: 0.5rem;
          width: 25rem;
          color: ${colors.sliver};
        }
      }
    }

    &__quantity {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      width: 100%;
      margin: 3rem 0;

      label {
        display: block;
        width: 100%;
        margin-bottom: 1rem;
        color: ${colors.colorShad};
      }

      input[type="number"]::-webkit-inner-spin-button,
      input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      input[type="number"] {
        -moz-appearance: textfield;
      }
      input {
        width: 4.5rem;
        height: 4.5rem;
        line-height: 1.65;
        display: block;
        padding: 0;
        margin: 0;
        padding-left: 20px;
        border: 1px solid #eee;
      }

      input:focus {
        outline: 0;
      }

      .quantity-nav {
        display: flex;
        flex-direction: column;

        button {
          width: 4.5rem;
          height: 2.25rem;
          border: 0.1rem solid #eee;
          background-color: ${colors.white};
          color: ${colors.sliver};
          cursor: pointer;

          &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }
        }
      }
    }

    &__addCard {
      width: 100%;
      margin-top: 3rem;

      button {
        ${buttonOne};
      }
    }
  }
`

export default Details
